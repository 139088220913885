.EDashboardMain{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #141416;
    overflow-x: hidden;
}

.root{
    flex-grow: 1;
}

.Navigation{
    width: 19%;
    height: 100%;
    z-index: 2;
    background-color: #0d0d0e;
    display: flex;
    flex-direction: column;
}

.navContent {
    min-height: 100%;
}

.navItem{
    padding-top: 11%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.navItemHeader{
    padding-left: 11%;
    font-style: bold;
    font-family: 'Work Sans Bold';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.navItemIcon{
    opacity: 0.5;
    color: #fff;
}

.navSelect{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 4.5%;
    padding-left: 11.6%;
}

.navItemText, .tempNavItemText {
    width: auto;
    padding-left: 4.2%;
    opacity: 0.6;
    font-family: 'Work Sans';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.navItemText:hover {
    text-decoration: underline;
    cursor: pointer;
}

.navHeader{
    width: 100%;
    height: 9%;
    background-color: #0d0d0e;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contentHeader{
    width: 100%;
    height: 9%;
    background-color: #141416;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dividerVertical{
    width: 1px;
    height: 100%;
    background-color: #2b2b2d;
    z-index: 3;
}

.dividerHorizontal{
    width: 100%;
    height: 1px;
    background-color: #2b2b2d;
    z-index: 3;
}

.EDashboardContent{
    width: 81%;
    height: 100%;
    background-color: #141416;
}

.Announcement{
    width: 100%;
    height: 42px;
    display: flex;
    flex-direction: row;
    background: #2E2E2E;
}

.AnnouncementContainer{
    width: 94%;
    height: 42px;
}

.AnnouncementMask{
    width: 100%;
    height: 42px;
    position: relative;
    top: -16px;
}

.AnnouncementText{
    position: relative;
    width: 94%;
    height: 16px;
    font-family: Courier;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    white-space: nowrap;
    color: #fff;
}

.AnnouncementClose {
    width: 6%;
    height: 42px;
    z-index: 2;
    display: flex;
    background-color: #2E2E2E;
    justify-content: center;
    align-items: center;
  }

.AnnouncementCloseOval {
    width: 21px;
    height: 20px;
    border: solid 1px #ffffff;
    text-align: center;
    color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
}

   .AnnouncementText p {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
   }

   /* Move it (define the animation) */
   @-moz-keyframes scroll-left {
    0%   { -moz-transform: translateX(100%); }
    100% { -moz-transform: translateX(-100%); }
   }
   @-webkit-keyframes scroll-left {
    0%   { -webkit-transform: translateX(100%); }
    100% { -webkit-transform: translateX(-100%); }
   }
   @keyframes scroll-left {
    0%   { 
    -moz-transform: translateX(100%); /* Browser bug fix */
    -webkit-transform: translateX(100%); /* Browser bug fix */
    transform: translateX(100%); 		
    }
    100% { 
    -moz-transform: translateX(-100%); /* Browser bug fix */
    -webkit-transform: translateX(-100%); /* Browser bug fix */
    transform: translateX(-100%); 
    }
   }

.WLLogo{
    padding-left: 10%;
}

.Emoji{
    width: 40px;
    height: 47%;
    font-size: 35px;
    padding-left: 12%;
}

.CurrentDate{
    font-family: 'Work Sans Medium';
    padding-left: 7%;
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.userGreeting{
    white-space: nowrap; 
    font-family: 'Work Sans Medium';
    padding-right: 5%;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.dateGreeting{
    width: 18%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.EDashboardModules{
    width: 100%;
    height: 86%;
    display: flex;
    justify-content: space-evenly;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow-y: scroll;
}

.moduleText{
    font-family: 'Work Sans Medium';
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.moduleText1{
    padding-top: 3%;
}

.moduleText2{
    padding-top: 1%;
}

.module1{
    height: 41.7%;
    width: 44.4%;
    display: flex;
    flex-direction: column;
}

.module2{
    height: 34.1%;
    width: 28.3%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 4%;
}

.moduleContainer1{
    width: 100%;
    height: 99%;
    border-radius: 2px;
    border: solid 1px #2b2b2d;
    display: flex;
}

.moduleContainer2{
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: solid 1px #2b2b2d;
    display: flex;
}

.PDContainer{
    align-items: center;
    overflow: hidden;
    flex-wrap: nowrap;
    position: relative;
}

.PDNumber{
    padding-left: 8.3%;
    font-family: 'Work Sans Black';
    font-size: 136px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.PDText{
    width: 10%;
    padding-left: 8.3%;
    opacity: 0.6;
    font-family: 'Work Sans';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.FlyCash{
    font-size: 45px;
    padding-left: 7.7%;
    padding-bottom: 15%;
    position: absolute;
    z-index: 2;
}

.FlyCash1{
    font-size: 45px;
    z-index: 2;
    flex: 100%;
    text-align: center;
}

.FlyCash2{
    font-size: 45px;
    z-index: 2;
    flex-grow: 2;
    flex: 50%;
    text-align: center;
}

.FlyCash3{
    font-size: 45px;
    z-index: 2;
    flex-grow: 1;
    flex: 33%;
    text-align: center;
}

.EmojiPyramid{
    width: 48.4%;
    padding-left: 70%;
    padding-top: 14%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
}

.PollsContainer{
    flex-direction: column;
}

.PollsName{
    padding: 6.1% 0% 0% 0%;
    margin: 0 0 0 6.1%;
    opacity: 0.6;
    font-family: 'Work Sans';
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.PollsQuestion{
    padding: 3.1% 0 6.1% 0;
    margin: 0 0 0 6.1%;
    font-family: 'Work Sans';
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.PollsButtons{
    width: 94%;
    height: 23%;
    display: flex;
    flex-direction: row;
}

.PollsButton{
    padding: 2% 0 0 0;
}

.WCContainer{
    flex-direction: column;
}

.EventDate{
    margin: 6.1% 0 0 6.1%;
    opacity: 0.6;
    font-family: 'Work Sans';
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.EventDesc{
    margin: 3.1% 0 0 6.1%;
    font-family: 'Work Sans';
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;

}

.checkMark{
    opacity: 0.6;
}

.checkMark2{
    opacity: 0.6;
    padding-right: 4%;
}

.cashContainer{
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 108px;

}

.WCButton{
    padding-left: 6.1%;
    height: 23%;
    width: 57%;
}

.vacUpcoming{
    width: 37%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.plusSign{
    opacity: 0.5;
    color: #fff;
    margin: 0 8px;
}

.vacContainer{
    width: 63%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.remainingContainer{
    width: 100%;
    height: 52%;
    display: flex;
}

.upcomingHoliday{
    width: 100%;
    height: 48%;
}

.remainingNumber{
    margin: 0;
    font-family: 'Work Sans Black';
    font-size: 45px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;

}

.dayContainer1{
    padding: 6.2% 0 0 3.2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dayContainer2{
    padding: 6.2% 0 0 3.2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.takenDays{
    margin: 0;
    opacity: 0.6;
    font-family: 'Work Sans';
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-align: center;
}

.dayName{
    margin: 0;
    opacity: 0.6;
    font-family: 'Work Sans';
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.vacText{
    margin: 10.5% 0 0 10.5%;
}

.vacDate{
    margin: 5.3% 0 0 10.5%;
    font-family: 'Work Sans';
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.vacStatus{
    display: flex;
    margin: 2.7% 0 0 10.5%;

}

/* .vacButton{
    width: 56%;
    height: 77.2%;
    margin: 2.7% 0 0 10.5%;
} */

.vacDays{
    height: 83%;
}

.vacOuterButton{
    height: 25%;
}

.vacButton:hover{
    cursor: pointer;
}

.WCButton:hover{
    cursor: pointer;
}

.emptyRequest{
    margin: 5.3% 0 0 10.5%;
    font-family: 'Work Sans';
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.requestInputs{
    height: 24%;
    z-index: 4;
    position: absolute;
    border-radius: 2px;
    border: solid 1px #2b2b2d;
    display: flex;
    background-color: #141416;
}

.datePicker{
    color: #fff;
}

.inputContainer{
    display: flex;
    flex-direction: column;
    
}

.requestButtons{
    display: flex;
    height: 17%;
}

.requestButton1{
    height: 100%;
    width: 27%;
    padding-left: 14%;
}

.requestButton2{
    height: 100%;
    width: 34%;
    padding-left: 10%;

}

.hoursContainer{
    flex-direction: column;
}

.moduleBorder{
    border-radius: 2px;
    border: solid 1px #2b2b2d;
    width: 100%;
    height: 99%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.moduleBorderVac{
    border-radius: 2px;
    border: solid 0px #2b2b2d;
    width: 100%;
    height: 99%;
    display: flex;
    overflow: hidden;
}

.module2Border{
    border-radius: 1px;
    border: solid 1px #2b2b2d;
    width: 100%;
    height: 99%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.hoursBreakdown{
    width: 100%;
    height: 77%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.hoursInformation{
    width: 100%;
    height: 23%;
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.forwardArrow{
    margin: 0 8px;
}

.forwardArrow2{
    padding: 0 0 0 9%;
}

.hoursLastUpdated{
    padding-left: 4%;
    opacity: 0.6;
    font-family: 'Work Sans';
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff
}
/* .harvestLink{
    padding-right: 2%;
    width: 21%;
    height: 76%;
} */

.BarChart{
    width: 51%;
    height: 87.1%;
}

.circularProgress{
    height: 100%;
    width: 50%;
}

.progressLabel{
  font-family: 'Work Sans Medium';
  font-size: 21px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.billableText{
    font-family: 'Work Sans Medium';
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.hoursText{
    padding-left: 8%;
    opacity: 0.6;
    font-family: 'Work Sans';
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.outerHoursKey{
    height: 68%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.billableOval {
    width: 13px;
    height: 13px;
    background-image: linear-gradient(11deg, #31ff98 -63%, #3fd0f2 93%);
    border-radius: 50%;
}

.nonBOval {
    width: 14px;
    height: 14px;
    background-image: linear-gradient(47deg, #9358f5 1%, #dd2476 96%);
    border-radius: 50%;
}

.billableHours{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 7%;

}

.nonBHours{
    padding-left: 7%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.authHarvest{
    position: absolute;
    height: 18%;
    width: 60%;
    z-index: 3;
}

.authHarvest:hover{
    cursor: pointer;
}

.authGoogle{
    position: absolute;
    height: 22.2%;
    width: 90%;
    z-index: 3;
}

.authGoogle:hover{
    cursor: pointer;
}

/* sign in page */

.signInMain{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.signInRight{
    width: 38%;
    height: 100%;
    background-color: #141416;
}

.signInLeft{
    height: 100%;
    width: 62%;
    display: flex;
    background-color: #000000;
}

.signInText{
    font-family: 'Work Sans Black';
    font-size: 52px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding-left: 14.7%;
    padding-top: 50.1%;
}

.processOutcomeText{
    font-family: 'Work Sans Black';
    font-size: 36px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding-left: 14.7%;
    padding-top: 50.1%;
}

.signInUsername{
    width: 71%;
    height: 8%;
    margin-left: 14.7%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #2b2b2d;
    padding-top: 5.5%;
}

.signInButton{
    width: 22.3%;
    height: 5.6%;
    padding-left: 63.1%;
    padding-top: 48%;
}

.processOutcomeButton{
    width: 22.3%;
    height: 5.6%;
    padding-left: 63.1%;
    padding-top: 48%;
}

.emailInput{
    border: none;
    height: 20px;
    width: 70%;
    opacity: 0.6;
    font-family: 'Work Sans';
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #FFFFFF;
    background-color: #141416;
}

.emailLabel{
    width: 100%;
}

.emailInput:focus{
    outline: none;
}

.emailIcon{
    width: 19px;
    height: 15px;
    padding-right: 4%;
}

.signInForgotP{
    margin-left: 14.7%;
    margin-top: 3.5%;
}

.logout {
    width: 100%;
    height: 54px;
    margin-top: -50px;
}

.logoutButton{
    background-color: #0d0d0e;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    padding-top: 4.5%;
    padding-left: 11.6%;
}

.HTBreakdown{
    width: 100%;
    height: 77%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.HTInformation{
    width: 100%;
    height: 23%;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 4%;
}

.HTContainer{
    display: flex;
    flex-direction: column;
}

.hourlyGoal{
    font-family: 'Work Sans Medium';
    font-size: 37px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.HourlyGoalText{
    
    opacity: 0.6;
    font-family: 'Work Sans';
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.HourlyGoalText2{
    opacity: 1;
    color: #ffffff;
    font-size: 13px;
}

.outerVacContainer{
    width: 100%;
    height: 40%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.module1Vac{
    height: 100%;
    width: 44.4%;
    display: flex;
    flex-direction: column;
}

.hoursDayOverview{
    width: 37%;
    height: 87.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hoursDayOverviewText{
    font-family: 'Work Sans Medium';
    opacity: 0.6;
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.hoursDayOverviewInformation{
    display: flex;
    flex-direction: row;
    align-items: center;
}